import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons"
import {
    HStack,
    VStack,
    Spacer,
    ButtonGroup,
    Button,
    Box,
    Text,
    Image,
    Stack,
    StackProps,
    Flex,
    useBreakpointValue,
    IconButton,
    useBoolean,
    Collapse,
} from "@chakra-ui/react"
import { FC } from "react"
import { IDE_URL } from "../constants"

const NavbarItems: FC<StackProps> = (props) => {
    return (
        <Stack spacing="0" gap="4" {...props}>
            <Text as="a" href="#mission" color="gray.500" fontWeight="medium">
                Mission
            </Text>
            <Text as="a" href="#features" color="gray.500" fontWeight="medium">
                Features
            </Text>
            <ButtonGroup colorScheme="brand" flexDirection="inherit" gap={{ base: "inherit", lg: "1" }}>
                <Button as="a" href={IDE_URL} size="md" variant="outline">
                    Sign In
                </Button>
                <Button as="a" href={IDE_URL} size="md" variant="solid">
                    Get Started
                </Button>
            </ButtonGroup>
        </Stack>
    )
}

const DesktopNavbar = () => {
    return <NavbarItems display={{ base: "none", lg: "flex" }} alignItems="center" flexDirection="row" />
}

const MobileNavbarButton: FC<{ isOpen: boolean; onToggle: () => void }> = ({ isOpen, onToggle }) => {
    return (
        <IconButton
            onClick={onToggle}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label="Toggle navigation"
            display={{ base: "flex", lg: "none" }}
        />
    )
}

const MobileNavbar: FC<{ isOpen: boolean }> = ({ isOpen }) => {
    return (
        <Collapse in={isOpen} animateOpacity>
            <NavbarItems
                mt="5"
                display={{ base: "flex", lg: "none" }}
                w="full"
                flexDirection="column"
                alignItems="flex-end"
            />
        </Collapse>
    )
}

const ResponsiveNavbar = () => {
    const [isMobileNavOpen, setMobileNavOpen] = useBoolean()
    return (
        <Box w="75%">
            <HStack w="full">
                <HStack>
                    <Box width="40px" display="inline-block">
                        <Image src="/logo.png" alt="Dyno Dino" />
                    </Box>
                    <VStack spacing="0" alignItems="flex-start">
                        <Text fontWeight="bold" fontSize="xl">
                            Dyno
                        </Text>
                        <Text fontSize="xs">Prompt Engineering IDE</Text>
                    </VStack>
                </HStack>
                <Spacer />
                <DesktopNavbar />
                <MobileNavbarButton isOpen={isMobileNavOpen} onToggle={() => setMobileNavOpen.toggle()} />
            </HStack>
            <MobileNavbar isOpen={isMobileNavOpen} />
        </Box>
    )
}

export default ResponsiveNavbar
