import {
    Box,
    Button,
    Center,
    Highlight,
    HStack,
    Icon,
    Image,
    Link,
    SimpleGrid,
    Spacer,
    Stack,
    Text,
    VStack,
} from "@chakra-ui/react"
import ResponsiveNavbar from "./components/ResponsiveNavbar"
import { BsLayoutSplit, BsViewStacked } from "react-icons/bs"
import { MdGeneratingTokens } from "react-icons/md"
import { CopyIcon } from "@chakra-ui/icons"
import { IDE_URL } from "./constants"

const UnderlineText = ({ text }: { text: string }) => {
    return (
        <Highlight query={text} styles={{ textDecoration: "underline", textDecorationColor: "brand.500" }}>
            {text}
        </Highlight>
    )
}

const App = () => {
    return (
        <Center>
            <VStack pt="3%" gap="7">
                <ResponsiveNavbar />
                <VStack w="75%" gap="5" textAlign="center">
                    <Box>
                        <Text fontSize="5xl" fontWeight="semibold">
                            Welcome to your <UnderlineText text="Prompt" />
                        </Text>
                        <Text fontSize="5xl" fontWeight="semibold">
                            <UnderlineText text="Engineering" /> toolkit
                        </Text>
                    </Box>
                    <Text>Quickly develop, iterate upon, and test LLM prompts</Text>
                    <Button as="a" href={IDE_URL} colorScheme="brand">
                        Try Dyno for Free
                    </Button>
                </VStack>
                <Box w="75%" position="relative">
                    <Image src="/ui.png" alt="Dyno UI" />
                    <Box
                        zIndex="-1"
                        position="absolute"
                        top="calc(-20%)"
                        w="100vw"
                        left="calc(-50vw + 50%)"
                        height="calc(100%)"
                        background="radial-gradient(50% 50% at center, transparent 10%, white 100%), url(/home-ui-bg.svg)"
                    />
                </Box>
                <VStack bg="blue.500" py="10%" px="10" w="full" textAlign="center" color="white" id="mission">
                    <Text fontWeight="semibold" fontSize="4xl">
                        We're in pursuit of the best Prompt Engineering experience
                    </Text>
                    <Text fontSize="xl">
                        Our goal is to provide a robust suite of tooling to make Prompt Engineering a painless
                        experience.
                    </Text>
                </VStack>
                <VStack w="full" spacing="10" py="10%" px="5%" id="features">
                    <Text
                        fontWeight="semibold"
                        fontSize="4xl"
                        mr={{ base: 1, lg: 2 }} /* mirror the grid spacing to make it equal */
                    >
                        Using Playground sucks. We fixed it.
                    </Text>
                    <SimpleGrid columns={{ base: 1, lg: 2 }} spacing="10">
                        <Stack>
                            <Icon as={BsLayoutSplit} color="brand.500" fontSize="5xl" />
                            <Text fontWeight="semibold">Dual Wield Prompts</Text>
                            <Text>
                                Stop relying on multiple tabs to test different prompts at the same time. Create a split
                                view to work on multiple prompts at once.
                            </Text>
                        </Stack>
                        <Stack>
                            <Icon as={MdGeneratingTokens} color="brand.500" fontSize="5xl" />
                            <Text fontWeight="semibold">Token Usage Insights</Text>
                            <Text>
                                LLM usage costs real money. We'll help you better understand the cost of your prompts.
                            </Text>
                        </Stack>
                        <Stack>
                            <CopyIcon color="brand.500" fontSize="5xl" />
                            <Text fontWeight="semibold">Save Prompts for Later</Text>
                            <Text>
                                Your notes app needs a break. Duplicate and save prompts for later while you work on
                                others.
                            </Text>
                        </Stack>
                        <Stack>
                            <Icon as={BsViewStacked} color="brand.500" fontSize="5xl" />
                            <Text fontWeight="semibold">Generate Multiple Outputs</Text>
                            <Text>
                                Life life on the edge with a temperature greater than zero. Better understand how your
                                users' output will look over time.
                            </Text>
                        </Stack>
                    </SimpleGrid>
                </VStack>
                <VStack spacing="3" pb="10%" textAlign="center">
                    <Text fontWeight="semibold" fontSize="5xl">
                        What are you waiting for?
                    </Text>
                    <Button as="a" href={IDE_URL} colorScheme="brand" size="lg">
                        Try Dyno for Free
                    </Button>
                </VStack>
                <HStack bg="blue.500" w="full" color="white" p="5" fontWeight="medium">
                    <Text>&#169; {new Date().getFullYear()} trydyno.com</Text>
                    <Spacer />
                    <Link href="https://embed.trydyno.com">Embed</Link>
                    <Link href="mailto:contact@trydyno.com">Contact Us</Link>
                </HStack>
            </VStack>
        </Center>
    )
}

export default App
